<!--  -->
<template>
  <div class="downCon">
    <div class="left">
      <img :src="qrcode" />
    </div>
    <div class="right">
      <div class="downWin" @click="downLoadPc">
        <img src="../../assets/images/d_pc.png" />
        <span>电脑端下载</span>
      </div>
      <div class="downAZ" @click="downloadAZ" type="large">
        <img src="../../assets/images/d_az.png" />
        <span>安卓端下载</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  components: {},
  data() {
    return {
      qrcode: null,
      apkurl: null,
    };
  },
  computed: {},
  created() {
    this.getQrcode();
    this.getApkurl();
  },
  methods: {
    downLoadPc() {
      this.$bus.$emit("downLoadPc");
    },
    downloadAZ() {
      window.location.href = this.apkurl;
    },
    async getQrcode() {
      let res = await axios.get("https://website.upupoo.com/official/qr_code/qrcode");
      // console.log('res:',res);
      this.qrcode = `data:image/png;base64,${res.data.data.qr_code}`;
    },
    async getApkurl() {
      let res = await axios.get("https://website.upupoo.com/official/qr_code/app/download");
      // console.log('res', res);
      this.apkurl = res.data.data.qr_code;
    },
  },
};
</script>

<style lang="scss" scoped>
.downCon {
  width: 460px;
  height: 201px;
  background: rgba(192, 227, 254, 0.28);
  border-radius: 16px;
  position: absolute;
  left: 310px;
  top: 317px;
  display: flex !important;
  justify-content: space-between;
  box-sizing: border-box;
  .left {
    width: 166px;
    height: 166px;
    background: #000000;
    border-radius: 14px;
    border: 2px solid #2197f9;
    margin-top: 16px;
    margin-left: 18px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .right {
    width: 242px;
    margin-top: 16px;
    margin-right: 18px;
    .downWin {
      width: 244px;
      height: 94px;
      background: #3da7fe;
      border-radius: 14px;
      border: 2px solid #2197f9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      cursor: pointer;
      box-sizing: border-box;
      img {
        width: 30px;
        margin-right: 8px;
      }
      span {
        font-weight: bold;
        font-size: 24px;
        color: #ffffff;
      }
      &:hover {
        background: #7ec5ff;
        border: 2px solid #2197f9;
      }
    }
    .downAZ {
      width: 242px;
      height: 66px;
      background: #f9fdff;
      border-radius: 16px;
      border: 2px solid #a2a5a6;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-sizing: border-box;
      img {
        width: 30px;
        margin-right: 8px;
      }
      span {
        font-weight: bold;
        font-size: 24px;
        color: #161616;
      }
      &:hover {
        background: #f9fdff;
        border: 2px solid #3da7fe;
      }
    }
  }
}
</style>
